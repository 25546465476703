import React from 'react';
import { ApolloProvider } from '@apollo/client';
import { useNavigate } from 'react-router-dom';
import './App.css';
import client from './graphql/client';

function App() {
  const navigate = useNavigate();

  const handleCreateProject = () => {
    navigate('/create-project');
  };

  return (
    <ApolloProvider client={client}>
      <div className="App">
        <header className="App-header">
          <h1>Welcome to Henry AI</h1>
          <button className="App-button" onClick={handleCreateProject}>Create a new project</button>
        </header>
      </div>
    </ApolloProvider>
  );
}

export default App;
